.sym-nav.ant-menu-horizontal {
  border-bottom-color: transparent;
}
.sym-nav.ant-menu-horizontal > .ant-menu-item > a,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title > a,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-title-content > a,
.sym-nav.ant-menu-horizontal > .ant-menu-item a,
.sym-nav.ant-menu-horizontal > .ant-menu-horizontal > .ant-menu-item > a {
  color: white;
}
.sym-nav.ant-menu-horizontal > .ant-menu-item-active a,
.sym-nav.ant-menu-horizontal > .ant-menu-item-selected a,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-title-content > a,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-title-content > a {
  color: #FF6B00;
}
.sym-nav.ant-menu-horizontal > .ant-menu-item,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu,
.sym-nav.ant-menu-horizontal > .ant-menu-item,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu:hover,
.sym-nav.ant-menu-horizontal > .ant-menu-item-active,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-active,
.sym-nav.ant-menu-horizontal > .ant-menu-item-selected,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-width: 4px;
}
.sym-nav.ant-menu-horizontal > .ant-menu-item .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-item .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu:hover .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-item-active .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-active .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-item-selected .ant-menu-submenu-title .anticon,
.sym-nav.ant-menu-horizontal > .ant-menu-submenu-selected .ant-menu-submenu-title .anticon {
  margin-right: 0;
  margin: 0 -0.3em;
  padding: 0.6em;
  background-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 100%;
}
