.header {
  position: relative;
  background-color: #393e41;
  height: 60px;
  padding-left: 30px;
  padding-right: 39px;
}

.logo {
    height: 40px;
}

.navContent {
  display: flex;
  justify-content: space-between;
  min-width: 800px;
  margin: auto;
}

.nav {
  background: transparent;
  line-height: 56px;
}

.nav > * {
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: 100;
  letter-spacing: 0.1em;
  color: #ffffff !important;
}

.quayKey {
    margin-top: 1.1em;
}

.quayKeyButton {
    width: 100%;
    margin-bottom: 0.5em;
}

.quayKeyWarningBox {
    padding: 1em;
    background-color: #ffdf7f;
    border: 1px solid #ffc107;
    border-radius: 3px;
}
