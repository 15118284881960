.main {
  display: flex;
  padding: 2em;
  min-width: 800px;
  max-width: 1200px;
  margin: auto;
}

.capitalize {
  text-transform: capitalize;
}

.sidebar {
  flex-basis: 250px;
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  flex-basis: 500px;
  flex-grow: 1;
  flex-shrink: 0;
}

.box {
  margin: 1em;
  padding: 1.3em;
  border: 1px solid #ccc;
  border-radius: 0.16em;
  background-color: #fff;
  box-shadow: 0 0 #a0a0a0;
  font-weight: 300;
}

.userInfo p {
  margin: 0;
}

.userDetails {
  margin: 1.3em 0;
  padding-bottom: 1.3em;
  border-bottom: 1px solid #ccc;
}

.header {
  font-size: 1.3em;
  font-weight: 400;
  margin-bottom: 0.6em;
}

.package {
  position: relative;
  margin: 1.3em;
  padding-bottom: 1.3em;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
}

.heading {
  text-transform: capitalize;
}

.info {
  opacity: 0.5;
  font-size: 0.8em;
  line-height: 1.6em;
}

.downloadButton {
  position: absolute;
  background-color: #1fb7fc;
  color: #fff;
  z-index: 10;

  cursor: pointer;
  transition: 200ms;
  border: 0;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 13px;
}

.downloadButton:hover {
  filter: opacity(80%);
}

.package:last-child {
  border-bottom: 0;
}

.buttonContainer {
  width: 83px;
}

.loading .downloadButton {
  opacity: 0.2;
  z-index: -1;
}
